.refinement-custom-table {
  /* border: 1px solid black; */
  /* padding: 10px; */
  margin: 10px !important;
  border-collapse: collapse !important;
}

.refinement-custom-table td {
  border: 1px solid lightgray !important;
  /* margin: 10px; */
  padding: 5px !important;
  text-align: justify;
}

.refinement-custom-table th {
  background-color: #274364 !important;
  color: #a9b6c8 !important;
  box-sizing: border-box !important;
  /* border: 1px solid lightgrey !important; */
  padding: 12px !important;
  font-size: 12px !important;
  word-wrap: break-word !important;
  text-align: center;
}

.avg-wealth-table td {
  padding: 1px 25px !important;
}
