.indent {
    margin-left: 1em;
  }
  
  .no-padding {
    padding: 0px !important;
    margin: 0px !important;
  }

  .title {
      justify-content: start;
  }
  .p-5{
    padding: 5px;
  }
  .mr-5{
    margin-right: 8px;
  }
  .d-block{
    display: block;
  }
  .helpview-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .left-width-help{
    width: 20%;
  }
  .right-width-help{
    width:80%
  }
  .clickable {
    cursor:pointer;
    color:blue;
    text-decoration:underline;
    padding:10px
  }
  .line-height{
    line-height: 200%;
  }
  p{
    line-height: 200%;
  }

  .help-custom-table {
    /* border: 1px solid black; */
    padding: 10px;
    /* margin: 10px !important; */
    width:30%;
    border-collapse: collapse !important;
    border-color: grey;
    border-spacing: 0 10px;
  }
  
  .help-custom-table tr {
    border: 1px solid rgb(10, 10, 10) !important;
    /* margin: 10px; */
    padding: 10px !important;
    text-align: left !important;
  }
  
  .help-custom-table th {
    background-color: #274364 !important;
    color: #a9b6c8 !important;
    /* border: 1px solid lightgrey !important; */
    padding: 12px !important;
    font-size: 12px !important;
    word-wrap: break-word !important;
    text-align: center;
  }