.filter-dimensions-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.filter-view-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}

.filter-view-sub-grid {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 8px;
}

.left-width {
  width: 64%;
}

.right-width {
  width: 35%;
}

.horizontal-line-divider {
  box-sizing: border-box;
  width: 95%;
  border: 0.5px solid lightgray;
  margin: 15px auto;
}

.city-filter th{
  font-weight: normal;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}






