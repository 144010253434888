.sub-filter-box {
  margin: 10px;
  /* height: 250px; */
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid lightgray;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.filter-view-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}
.flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 32%;
}
.flex-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  flex: 32%;
  padding: 2px;
}
.w-100 {
  width: 100%;
  /* margin-left: 50px; */
}
.input {
  padding: 5px;
  margin-left: 500px;
}
.container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.close-button {
  /* margin-top: 1em; */
  float: right;
}
.close-button:before {
  content: "✖";
  /* border: 1px solid gray; */
  /* background-color: #eee; */
  padding: 0.5em;
  cursor: pointer;
}

.sub-filter {
  overflow: scroll;
  height: 500px;
}

.sub-filter th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
}
