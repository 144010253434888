.fund-metadata-box {
  margin-top: 10px;
  height: 70px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid lightgray;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 32%;
}

.vertical-line-divider {
  box-sizing: border-box;
  height: 40%;
  width: 1px;
  border: 0.5px solid #b2b2b2;
  margin: auto;
}

.section-header {
  height: 24px;
  /* width: 105px; */
  color: #999999;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 24px;
}

.section-value {
  height: 25px;
  /* width: 156px; */
  color: #333333;
  font-family: Roboto;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 25px;
}
