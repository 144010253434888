.multi-list-chooser-options {
  display: flex;
  flex-direction: column;
  min-width: 130px;
  align-content: space-around;
}

.multi-list-chooser-buttons {
  margin-top: 5px;
}
