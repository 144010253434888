@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500,600,700|Roboto:300,400,500,600,700&subset=greek");
@import url("https://unpkg.com/normalize.css@8.0.1/normalize.css");

body {
  margin: 0;
  font-family: "roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px !important;
  line-height: 1.2rem !important;
}

/* Hide Scrollbar

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

html {
  position: relative;
  min-height: 100%;
}

.footer {
  height: 50px;
  margin-top: -50px;
}
.footer-margin {
  margin-left: 125px;
}
.ml-3 {
  margin-left: 30px;
}
html,
body,
#app,
#app > div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  min-height: 100vh;
}

.center {
  margin: auto;
}

.login-main {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 100vh;
}

main {
  padding-top: 100px;
  height: 100%;
  /* margin-bottom: 100px; */
}

header {
  background-color: #fff;
  z-index: 1000;
}

.footer {
  margin-top: -50px;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  height: 100px;

  background-color: #2e3a4b;
  background-image: url("./common/assets/bg_footer.png");
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}

.right-align {
  position: absolute;
  right: 0px;
}

.iss-main-header {
  background-size: 100%;
  background-repeat: repeat;
  background-image: url("./common/assets/bg_Header.png");
  color: #fff;
  height: 60px;
}

.iss-main-header-logo {
  padding-top: 0px;
  padding-left: 15px;
  height: 55px;
  width: 200px;
}

.logout-main {
  position: fixed;
  right: 20px;
  top: 12px;
  padding: 0.25rem 0.5rem;
}

.iss-bg-subheader {
  color: #fff;
  background-color: rgba(43, 96, 158, 0.2);
  height: 40px;
}

.iss-btn-outline-transparent {
  border: 1px solid #ffffff !important;
  border-radius: 3px !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  cursor: pointer !important;
  color: white !important;
}

.dropdown-toggle {
  padding: 7px;
}

.iss-btn-outline-transparent:focus {
  border: 1px solid #ffffff !important;
  border-radius: 3px !important;
  box-shadow: none !important;
}

.iss-subheader-text {
  color: #2b609e;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
}

.iss-bg-subheader .active {
  color: rgba(43, 96, 158, 0.4);
}

.iss-footer-logo {
  height: 61px;
  width: 110px;
}

.f-left {
  float: left;
}

.iss-title {
  height: 18px;
  width: 401px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
}

.footer-links {
  margin: 0 1%;
  height: 11px;
  color: rgba(255, 255, 255, 0.5);
  font-family: Roboto;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 11px;
}

.footer-content {
  margin-top: 30px;
}

hr.footer-hr {
  margin: 0;
  width: 80%;
  background-color: white;
}

/* CONTENT HEADER */

.content-title-text {
  color: #2b609e;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
}

.content-hr {
  border: 1px solid #b2b2b2;
}

/* TAB SECTIONS */

.tab-button-divider {
  border: none;
  border-right: 1px solid #ccc;
}

.tab-button {
  height: 40px;
  /* width: 150px; */
  background-color: transparent;
  border: none;
  color: #717171 !important;
  font-family: Roboto !important;
  font-size: 15px !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  outline: none;
  transition: 0.3s !important;
}

.file-management-tab-item > .btn:focus {
  box-shadow: none;
}

.file-management-tab-item .active {
  color: #2b609e !important;
  border-bottom: 3px solid #2b609e;
}

.tab-content-section {
  /* transition: 0.3s !important; */
  /* display: none !important; */
}

.tab-content-section + .active {
  display: block !important;
}

/* FILTER SECTION */

.filter-clear {
  font-size: 12px;
  line-height: 30px;
  float: right;
  vertical-align: bottom;
  color: #2b609e;
}

.filter-plus-icon {
  line-height: 30px;
  float: right;
  vertical-align: bottom;
}

.filter-box-text {
  color: #030303 !important;
  font-family: Roboto !important;
  font-size: 22px !important;
}

/* COMMON CSS */

.section-header-text {
  height: 29px;
  color: black;
  font-family: Roboto;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 25px;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-95 {
  width: 95% !important;
}

.w-45 {
  width: 45% !important;
}

.w-20 {
  width: 20% !important;
}

.mw-200 {
  min-width: 200px !important;
}

.mw-400 {
  min-width: 400px !important;
}

.custom-table {
  /* box-shadow:  0 10px 0 rgba(0, 0, 0, 0.1); */
  border-radius: 3px;
  border-collapse: collapse;
}

.custom-table thead {
  background-color: #2b609e;
}

.custom-table th {
  border-bottom: 1px solid #999;
  color: rgba(255, 255, 255, 0.5);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.custom-table td {
  text-align: center;
  border-bottom: 1px solid #dee2e6;
}

.disabled-icon {
  color: #999;
}

.bg-primary-custom {
  background-color: #2b609e !important;
}

.btn-primary-custom {
  border: 1px #2b609e solid !important;
  border-radius: 3px !important;
  background-color: #2b609e !important;
}

.btn-primary-custom:disabled {
  background-color: lightgrey !important;
  border: 1px lightgrey solid !important;
}

.bg-primary-custom:hover {
  background-color: #2b609e !important;
}

.text-primary-custom {
  background-color: #2b609e !important;
}

.color-white {
  color: white !important;
}

.color-primary {
  color: #2b609e !important;
}

.custom-card {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: none !important;
  border-radius: 3px !important;
}

.report-settings-border {
  border: 1px solid #666;
}

.modal-header {
  max-height: 60px;
  border-radius: 5px 5px 0 0;
  background-color: #b76c00;
}
.p-10 {
  padding: 10%;
}
.modal-footer {
  border-top: none !important;
}
.m-auto {
  margin: auto;
}
.confirm-modal-title {
  color: #ffffff;
  font-family: Roboto;
  font-size: 24px !important;
  letter-spacing: 0;
  line-height: 28px !important;
}

.close {
  color: #fff !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
}
.confirm-modal-body {
  color: #333333 !important;
  font-family: Roboto;
  font-size: 22px !important;
  letter-spacing: 0;
  line-height: 25px;
}

.modal-confirm-button {
  /* padding: none; */
  border-color: inherit !important;
  /* height: 24px; */
  width: 160px;
  color: #ffffff !important;
  font-family: Roboto;
  font-size: 15px !important;
  background-color: #b76c00 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  text-align: center;
}

.modal-confirm-button:hover {
  border-color: inherit !important;
}

.modal-close-button {
  /* padding: none; */
  border-color: none !important;
  /* height: 24px; */
  border-color: #b76c00 !important;
  min-width: 100px;
  color: #b76c00 !important;
  font-family: Roboto;
  font-size: 15px !important;
  letter-spacing: 0;
  line-height: 24px !important;
  text-align: center;
}

.modal-close-button:hover {
  background-color: inherit !important;
}

.color-danger {
  color: red !important;
}

.custom-nav {
  width: 100%;
}

.d-flex {
  display: flex;
}
.pl-1 {
  padding-left: 1%;
}
.logo {
}
.justify-space-around {
  display: flex;
  justify-content: space-around;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.upload-label {
  margin-right: 10px;
}

.survey-layout {
  margin-bottom: 20px;
  min-height: 80%;
}

.table-pagination {
  margin-top: 15px;
  float: right;
}
.table-font {
  font-size: 0.75em !important;
}
.download-button-div {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.data-group {
  margin-top: 0px !important;
}

.non-granularity-sub-group {
  border: 1px solid inherit;
  display: inline-flex;
  justify-content: space-around;
  width: 20%;
  padding: 5px;
}

.granularity-sub-group {
  border: 1px solid black;
  display: inline-flex;
  justify-content: space-around;
  width: 25%;
  padding: 5px;
}

.float-button {
  position: fixed;
  /* display: table;
  width: 50px;
  height: 50px; */
  bottom: 40px;
  right: 40px;
  /* background-color: #274364; */
  color: #fff;
  /* border-radius: 50px; */
  text-align: center;
  /* box-shadow: 2px 2px 3px #999; */
  cursor: pointer;
}

.float-button-color {
  background-color: #274364 !important;
  color: white !important;
}

.floated-icon {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
}

.filter-text {
  color: black;
}
thead {
  font-size: 12px;
  word-wrap: break-word;
  color: white;
}

th {
  background-color: #001d39;
  color: white;
}

td {
  font-size: 12px;
}

/* Weight and Concentration Background Color Schemes */

.context-color-1 {
  background-color: #a3fff9;
  color: #000000;
}

.context-color-2 {
  background-color: #70e0d9;
  color: #000000;
}

.context-color-3 {
  background-color: #638f94;
  color: #000000;
}

.context-color-4 {
  background-color: #3f6b70;
  color: whitesmoke;
}
.context-color-5 {
  background-color: #2f5054;
  color: whitesmoke;
}

.filter-color-1 {
  background-color: #fafac8;
  color: #000000;
}

.filter-color-2 {
  background-color: #ebebab;
  color: #000000;
}

.filter-color-3 {
  background-color: #b0b078;
  color: #000000;
}

.filter-color-4 {
  background-color: #635f39;
  color: whitesmoke;
}
.filter-color-5 {
  background-color: #454330;
  color: whitesmoke;
}
.float-button .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.float-button:hover .tooltiptext {
  visibility: visible;
}

.value-field-td {
  text-decoration: underline;
  text-align: start !important;
}

.margin-lr-10 {
  margin: 0 10px;
}

.mt-4-pct {
  margin-top: 4% !important;
}

.managed-modal {
  z-index: 1001;
}

.mt-1-pct {
  margin-top: 1% !important;
}

.mlr-10px {
  margin: 0 10px !important;
}

.right-align-text {
  text-align: right !important;
}

.cursor-pointer {
  cursor: pointer;
}

.datepicker-custom-style {
  box-shadow: inset 0 0 0 1px #969696;
  color: #333333;
  background-color: #ffffff;
  border-radius: 3px;
  border: 0;
  padding: 4px 4px;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.margin-15px {
  margin: 15px;
}
